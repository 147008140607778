
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'ActionButtons',
  computed: {
    ...mapState('tableau', ['vizObject']),
  },
  methods: {
    download() {
      if (this.vizObject) {
        this.vizObject.showExportPDFDialog();
      }
    },
    toggleFullScreen() {
      const domEl = document.querySelector('#tableauViz');
      if (domEl) {
        domEl.requestFullscreen();
      }
    },
    resetReport() {
      this.vizObject.revertAllAsync();
    },
    exportToExcel() {
      this.vizObject.exportCrossTabToExcel();
    },
  },
});
