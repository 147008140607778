
import Vue from 'vue';
import MenuList from '@/components/layout/Drawer/MenuList.vue';
import DrawerHeader from '@/components/layout/Drawer/DrawerHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import ActionButtons from '@/components/layout/ActionButtons.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    MenuList,
    DrawerHeader,
    ActionButtons,
  },
  data: () => ({
    drawer: null,
    langs: [
      { value: 'pl', name: 'PL' },
      { value: 'en', name: 'EN' },
      { value: 'de', name: 'DE' },
    ],
  }),
  computed: {
    ...mapGetters('tableau', [
      'getFirstProjectId',
      'getFirstWorkbookId',
      'getProjectNameById',
      'getWorkbookNameById',
    ]),
    appBarTitle(): string {
      const route = this.$route.name;
      if (route === 'Workbook') {
        const projectId = this.$route.params.projectId;
        return this.getProjectNameById(projectId);
      }
      if (route === 'Report') {
        const workbookId = this.$route.params.workbookId;
        return this.getWorkbookNameById(workbookId);
      }
      return '';
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('tableau', [
      'fetchProjects',
      'fetchWorkbooksByUser',
      'fetchWorkbookViews',
    ]),
    onLogoutClick() {
      this.logout();
    },
  },
  async created() {
    await this.fetchProjects();
    await this.fetchWorkbooksByUser();
  },
});
