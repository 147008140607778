<template>
  <v-list dense>
    <v-list-group link v-for="project in projects" :key="project.id">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ project.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-group
        sub-group
        link
        v-for="workbook in getWorkbooksByProjectId(project.id)"
        :key="workbook.id"
        :value="openSubGroup === workbook.id"
        @input="openSubGroup = openSubGroup === workbook.id ? null : workbook.id"
        @click="fetchWorkbookViews(workbook.id)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ workbook.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          link
          v-for="{ id, name, contentUrl } in actualViews"
          :key="id"
          @click="goToReport(id, contentUrl, project.id, workbook.id)"
        >
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list-group>
  </v-list>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

export default Vue.extend({
  data() {
    return {
      openSubGroup: null
    }
  }, 
  name: 'MenuList',
  computed: {
    ...mapState('tableau', ['actualViews','projects', 'workbooks']),
    ...mapGetters('tableau', ['getWorkbooksByProjectId']),
  },
  methods: {
    ...mapActions('auth', ['getTableauTicket']),
    ...mapActions('tableau', ['fetchWorkbookViews']),
    async goToReport(viewId, contentUrl, projectId, workbookId) {
      try {
        await this.getTableauTicket();
        this.$router.push({
          name: 'Report',
          params: {
            contentUrl,
            projectId: projectId,
            workbookId: workbookId,
            viewId,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
</script>

<style></style>
